import React from 'react';
import Layout from 'layouts/Layout';
import Container from 'layouts/Container';
import SEO from 'components/seo';
import styled from 'styled-components';
import { Colors } from 'styles';
import { graphql } from 'gatsby';
import { useTranslation } from 'react-i18next';

const Content = styled.div`
  margin: 2rem 0;
  background-color: ${Colors.bg_lite};
  padding: 1.5rem 2rem;

  & > .title {
    font-size: 2rem;
    color: ${Colors.gdmc_blue};
    text-align: center;
    margin-bottom: 1.5rem;
  }

  & > .content {
    border: 1px solid ${Colors.grey_lite};
    border-radius: 4px;
    overflow: hidden;
    margin-bottom: 1rem;
    img {
      max-width: 100%;
      margin: 0 auto;
      border: 1px solid ${Colors.bg_lite_grey};
    }
    padding: 2rem;

    & > p:last-child,
    & > ul:last-child,
    & > ol:last-child {
      margin-bottom: 0;
    }
  }
`;

const Custom = ({ location, data }) => {
  const { i18n } = useTranslation();
  const servicesData = data.prismicServices.data;
  return (
    <Layout location={location}>
      <SEO lang={i18n.language} title={servicesData.title.text} />
      <Container>
        <Content>
          <h1 className="title">{servicesData.title.text}</h1>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: servicesData.content.html }}
          />
        </Content>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ServicesQuery($lang: String) {
    prismicServices(lang: { eq: $lang }) {
      data {
        content {
          html
        }
        title {
          text
        }
      }
    }
  }
`;

export default Custom;
